@import 'variables';

.forms-header {
  text-align: center;
  margin-top: $medium-margin;
  margin-bottom: $ms-margin;

  img {
    max-width: 80px;

    @media (max-width: 768px) {
      width: 60px;
    }
  }

  p {
    color: $manatee;
    margin-top: $small-margin;
  }
}

.main-form {
  margin: auto;
  max-width: 400px;
  padding: $ms-padding;
  //border: 1px solid #AAAAAA;
  box-shadow: 0px 2px 4px #bcbcbc;
  border-radius: 10px;
  background-color: $white;

  .verify {
    font-weight: 400;
  }

  mat-form-field {
    width: 100%;

    .mat-form-field-infix {
      border-top: 0.4375em solid transparent;
    }

    .mat-form-field-flex {
      align-items: baseline;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    mat-icon {
      cursor: pointer;
    }
  }

  .title {
    font-size: $secondary-title;
    color: $davy-grey;
    margin-bottom: $base-margin;
  }

  .password-field {
    padding-right: 50px;
  }

  .form-field {
    display: flex;
    justify-content: space-between;
    margin-top: $base-margin;
    position: relative;

    input,
    textarea {
      width: 100%;
      background-color: inherit;
      font-size: $link-size;
      border-radius: 4px;
      border: 1px solid rgba(123, 123, 123, 1);
      padding: $base-padding;
    }

    textarea {
      resize: none;
      font-family: $primary-font;
      width: 100%;
      background-color: inherit;
      font-size: $link-size;
      border: 1px solid rgba(203, 203, 203, 1);
      border-radius: 4px;
      padding: $base-padding;
    }

    .show-password {
      color: $ultramarine-blue;
      cursor: pointer;
      position: absolute;
      top: 13px;
      right: 12px;
    }
  }

  a {
    color: $ultramarine-blue;
    font-weight: 500;
    display: inline-block;
    margin-top: 2 * $small-margin;
  }

  .submit-button {
    border: none;
    border-radius: 100px;
    background-color: $red-orange;
    padding: $small-padding;
    width: 100%;
    text-align: center;
    color: $white;
    margin-top: $ms-margin;
    font-size: $link-size;
    cursor: pointer;
  }
}

.form-field {
  display: flex;
  justify-content: space-between;
  margin-top: $base-margin;
  position: relative;

  input,
  textarea {
    width: 100%;
    background-color: inherit;
    font-size: $link-size;
    border-radius: 4px;
    border: 1px solid rgba(123, 123, 123, 1);
    padding: $base-padding;

    ::placeholder {
      color: $pastel-gray;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $pastel-gray;
    }

    ::-ms-input-placeholder {
      color: $pastel-gray;
    }
  }

  textarea {
    resize: none;
    font-family: $primary-font;
    width: 100%;
    background-color: inherit;
    font-size: $link-size;
    border: 1px solid rgba(203, 203, 203, 1);
    border-radius: 4px;
    padding: $base-padding;
  }

  .show-password {
    color: $ultramarine-blue;
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 12px;
  }
}

// .country-number-field {
//   .mat-form-field-underline {
//     display: none;
//   }

//   .mat-form-field-appearance-outline .mat-form-field-outline {
//     color: $davy-grey;
//   }
// }

.phone-country-select-trigger {
  .mat-select-arrow {
    border: solid get('grey', 700);
    border-width: 0px 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg) !important;
  }
}

.numberfield {
  .mat-form-field-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}

.sms-login-form {
  .mat-form-field-underline {
    background-color: get('grey', 100);
  }
}

.phone-country-select-trigger {
  .mat-select-arrow {
    border: solid get('grey', 700);
    border-width: 0px 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg) !important;
  }
}

.numberfield {
  .mat-form-field-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}

.sms-login-form {
  .mat-form-field-underline {
    background-color: get('grey', 100);
  }
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  border-radius: 20px 20px 0 0;
}

.order-filtering-bottom-sheet,
.order-filtering-dialog {
  min-height: 80vh;
  .filter-order-container {
    font-family: 'Geologica';
  }

  .filter-form {
    .mat-form-field-infix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 !important;
      border-top: 6px solid transparent;
    }
  }
}

.mat-select-panel {
  .mat-option-text {
    display: flex;
    align-items: center;

    // border-bottom: solid 4px rgba(108, 108, 108, 0.4);
    img {
      width: 20px;
      height: 20px;
      margin: 0px $small-margin;
      border-radius: 50%;
    }
  }
}

.static-container {
  mat-expansion-panel-header {
    padding: 12px 14px 0px;
  }

  div.mat-expansion-panel-body {
    // padding: 12px 14px;
    padding-top: 0 12px 14px;
  }
}

.date-form {
  .mat-form-field-outline {
    background-color: #d4e8ec;
    border-radius: 2px;
    border: none;
  }

  .mat-form-field-infix {
    display: flex;
    padding: 0;
    border-top: none;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }
}

.first-opt:nth-child(2) {
  border-bottom: 1px dotted gray;
  background-color: #e4e1e12e;
}

.mat-select-search {
  position: sticky !important;
  top: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
  padding: 5px 20px 0px;
}

.mat-checkbox .mat-checkbox-inner-container {
  border: 2px solid #fff;
  border-radius: 4px;
}

.dialog-full-width {
  max-width: none !important;
  background-color: #fbfbfb;
  border: 0.8px solid get('grey', 200);
  border-radius: 6px;

  .mat-dialog-container {
    box-shadow: none;
  }
}

.desktop-search-dialog {
  .mat-dialog-container {
    padding: 24px 16px;
  }
}

.payment-method-select {
  .mat-radio-label {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.order-filtering-dialog {
  min-height: unset !important;
}
