@import 'variables';
@import 'mixins';
@import 'basic';
@import 'class';
@import 'main-forms';
@import 'carousel';
@import 'static-pages';

shared-product-card {
  display: block;
  height: 100%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: get('primary', 400);
}

html {
  scroll-behavior: smooth;
}

html,
body,
app-root {
  height: 100dvh;
  overflow: hidden;
}

* {
  font-family: 'Geologica', Roboto, 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: 'Geologica', Roboto, 'Helvetica Neue', sans-serif;
}

mat-radio-button p {
  font-family: 'Geologica', Roboto, 'Helvetica Neue', sans-serif;
}

.contact-us {
  .contact-info {
    padding: $ms-padding 0;
    background-color: #eafffe;

    .row {
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    .info {
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      p {
        text-align: center;
        margin-top: $base-margin;
      }

      .title {
        font-size: $title-size;

        @media (max-width: 768px) {
          font-size: $secondary-title;
        }
      }

      .contact-detail {
        color: $red-orange;
        font-size: $secondary-title;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }

    .banner {
      width: 45%;

      @media (max-width: 768px) {
        width: 50%;
        margin: auto;
      }

      @media (max-width: 568px) {
        width: 80%;
        margin: auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .office {
    margin: $ms-margin 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 512px) {
        display: block;
      }
    }

    .country {
      width: 30%;
      padding: $medium-padding 0;

      @media (max-width: 768px) {
        padding: $base-padding 0;
        width: 49%;
      }

      @media (max-width: 512px) {
        padding: $base-padding 0;
        width: 100%;
      }
    }

    .country-name {
      padding-top: 2 * $small-margin;
    }

    .address-title,
    .phone-title {
      color: $light-gray;
      margin-top: 16px;
      margin-bottom: $small-margin;
    }

    .address,
    .phone {
      color: $light-gray;
      font-size: 14px;
    }
  }
}

.prevArrowBtn {
  border-radius: 50px;
  top: 35%;
  filter: invert(1);
}

.nextArrowBtn {
  border-radius: 50px;
  top: 35%;
  filter: invert(1);
}

.login-page .row-buttons .mat-button-wrapper {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.edit-profile .phone-row .mat-form-field-outline-end {
  border-radius: 0px;
}

.file-over {
  background-color: $overlay-background;
}

.edit-profile .date-field {
  mat-form-field-flex {
    padding-bottom: 0 !important;
  }
}

.server-image {
  width: 100px;
}

hr.custom-hr {
  border: 0;
  height: 1px;
  background: get('grey', 100);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-box {
  height: 26px;
  padding: 5px 12px 5px 12px;
  gap: 8px;
  border-radius: 4px;
  border: 0.8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  @extend .flex;
  justify-content: center;
}

.green-status {
  color: get('success', 600);
  border: 1px solid get('success', 200);
  background-color: get('success', 10);
}

.red-status {
  color: get('error', 600);
  border: 1px solid get('error', 200);
  background-color: get('error', 10);
}

.pending-status {
  color: get('info', 600);
  border: 1px solid get('info', 200);
  background-color: get('info', 10);
}

.button {
  @extend .flex;
  cursor: pointer;
  height: 32px;
  padding: 6px 16px 6px 16px;
  gap: 8px;
  border-radius: 6px;
  border: none;
  justify-content: center;

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: right;
  }

  img {
    width: 16px;
    height: 16px;
  }
}

.warn-button {
  @extend .button;
  background-color: get('warning', 400);
}

.primary-button {
  @extend .button;
  background-color: get('primary', 400);
  color: #fff;
  transition: 0.3s background-color;

  &.disabled {
    cursor: pointer;
    pointer-events: none;
    background-color: get('primary', 50);
    color: get('primary', 200);
  }
}

.w-full {
  width: 100%;
}

.phone-field-select {
  margin-top: 157px;
  max-width: 200px !important;
  margin-left: 16px;
  border: 0.8px solid get('grey', 100);
  box-shadow: none !important;
  height: 184px;
}

.country-option {
  .mat-option-text {
    justify-content: space-between;
  }
}

.logout-dialog mat-dialog-container.mat-dialog-container,
.order-detail-dialog mat-dialog-container.mat-dialog-container,
.theme-dialog mat-dialog-container.mat-dialog-container,
.edit-phone-dialog mat-dialog-container.mat-dialog-container,
.order-filtering-dialog mat-dialog-container.mat-dialog-container,
.preference-dialog mat-dialog-container.mat-dialog-container {
  padding: 0;
  border-radius: 6px;
}

.cookie-dialog mat-dialog-container.mat-dialog-container {
  border-radius: 8px;
  box-shadow: none;
  border: 0.8px solid get('grey', 100);
}

mat-dialog-container {
  padding: 0;
}

.cookie-dialog {
  margin-left: 16px;
  margin-right: 16px;
}

.settings-dialog-container {
  .mat-slide-toggle-bar {
    height: 16px;
    width: 36px;
    border-radius: 100px;
    background-color: get('grey', 100);
  }

  .mat-slide-toggle-thumb {
    top: 5px;
    right: -2px;
    position: relative;
    width: 12px;
    height: 12px;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: get('success', 400);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffffff;
    right: -6px !important;
  }
}

.theme-dialog-container {
  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 70%;
  }

  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
  }
}

.outline-button {
  @extend .button;
  border: 1px solid get('primary', 200);
  color: get('primary', 400);
  background-color: get('primary', 10);
}

.outline-warn-button {
  @extend .outline-button;
  border: 1px solid get('error', 200);
  color: get('error', 400);
  background-color: get('error', 10);
}

.transparent-button {
  color: get('grey', 700);
  background: initial;
  padding: 0;
}

.ms-padding {
  padding: 16px;
}

.base-margin-bottom {
  margin-bottom: $base-margin;
}

.medium-margin-bottom {
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

#freshworks-container {
  iframe {
    bottom: 118px !important;
    @include breakpoint-min($breakpoint-sm) {
      bottom: 22px !important;
    }
  }
}

.edit-user-profile {
  .mat-form-field-outline-end,
  .mat-form-field-outline-start {
    opacity: 0;
  }
}

.return-policy {
  color: get('grey', 500);

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: justify;
  }
}

.static-container {
  .head-wrapper {
    background: get('primary', 10);
    padding: 20px 16px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      color: get('primary', 500);

      @include breakpoint-min($breakpoint-sm) {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
      }
    }

    p {
      margin: 8px 0;
      font-size: 12px;
      color: get('grey', 600);

      @include breakpoint-min($breakpoint-sm) {
        font-size: 16px;
      }
    }
  }

  .hr-custom {
    margin: 16px 0 !important;
  }
  @include breakpoint-min($breakpoint-sm) {
    .important,
    .return-policy {
      font-size: 20px !important;
      line-height: 28px !important;
      .title {
        font-size: 24px !important;
        line-height: 36px !important;
      }
    }

    .return-policy {
      p {
        font-size: 18px !important;
        line-height: 28px !important;
      }
    }

    .detail-box {
      .title {
        font-size: 24px !important;
        line-height: 30px !important;

        &::before {
          height: 17px !important;
        }
      }

      .description {
        font-family: Geologica;
        font-size: 18px !important;
        line-height: 26px !important;
        text-align: justify;
      }
    }

    .border-box {
      .title {
        font-size: 20px !important;
        line-height: 30px !important;
      }

      .description {
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: justify;
      }
    }

    li {
      font-size: 16px !important;
      line-height: 24px !important;
    }

    .gray {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    .blue {
      font-size: 20px !important;
      line-height: 25px !important;
    }

    ul {
      margin: 0;
    }
    ul.dashed {
      list-style-type: none;
    }
    ul.dashed > li {
      text-indent: -5px;
    }
    ul.dashed > li:before {
      content: '-';
      text-indent: -5px;
    }
  }

  .important {
    padding: 20px 16px;
    border-radius: 6px;
    .title {
      color: get('primary', 500);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    background-color: get('grey', 10);
    color: get('grey', 700) !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }

  .detail-box {
    .title {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: get('grey', 800);
      margin-bottom: 8px;

      &::before {
        content: '';
        display: inline-block;
        height: 12px;
        border-left: 1px solid get('primary', 400);
        padding-left: 5px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: get('grey', 600);
      text-align: justify;
    }
  }

  .border-box {
    padding: 20px 16px;
    border: 0.8px solid get('grey', 100);
    border-radius: 6px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: get('grey', 800);
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: get('grey', 600);
      text-align: justify;
    }
  }

  li {
    font-family: Geologica;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
    color: get('grey', 500) !important;
    list-style: none;
  }

  a {
    color: get('info', 500);
  }

  .custom-hr {
    margin: 16px 0;
  }

  .gray {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: left;
    color: get('grey', 400);
  }

  .blue {
    font-family: Geologica;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: get('primary', 500);
  }

  .paragraph {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: get('grey', 700);
  }

  .question-box {
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.5px;
      text-align: left;
      color: get('grey', 700);
    }

    .description {
      font-family: Geologica;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      color: get('grey', 500);
      text-align: justify;
    }
  }

  table {
    tbody {
      tr > td {
        padding: 14px 8px;
        border: none !important;
        font-size: 12px;
      }
      & > tr:first-child {
        td {
          background: get('primary', 400);
          color: #ffffff;
          border: none;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          text-align: center;

          &:first-child {
            border-radius: 6px 0 0 0;
          }

          &:last-child {
            border-radius: 0 6px 0 0;
          }
        }
      }

      & > tr:nth-child(odd) {
        background-color: get('primary', 10) !important;
      }
    }
  }
}

.apple-signin-container {
  #appleid-signin {
    & > div {
      max-width: 592px !important;
    }
  }
}

#mainPage {
  scroll-margin: 500px;
}
