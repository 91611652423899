@import 'theme';

@font-face {
  font-family: 'Geologica';
  src: url('../../assets/fonts/Geologica-Regular.ttf');
}

@font-face {
  font-family: 'Geologica Bold';
  src: url('../../assets/fonts/Geologica-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Italic.ttf');
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fbfbfb;
  font-family: 'Geologica';
}

input,
button {
  font-family: 'Geologica';
}

p {
  margin: 0;
}

.default-expansion-container {
  mat-expansion-panel {
    background: get('grey', 50) !important;
    color: get('grey', 700) !important;
    border: 1px solid #fff;
    border-radius: 10px !important;
    margin: 15px 0 !important;

    mat-expansion-panel-header {
      padding: 12px 14px !important;
      background: rgba(0, 0, 0, 0.04) !important;
    }

    .mat-expansion-panel-body {
      padding: 0 14px 12px 14px;
    }

    p {
      font-family: $primary-font !important;
      color: get('grey', 600);
    }

    &.mat-expanded {
      background: get('grey', 50) !important;

      mat-panel-title {
        color: get('primary', 500) !important;
      }
    }
  }
  .mat-expansion-indicator::after {
    color: get('grey', 700) !important;
  }
}

#recaptcha-container > div > div,
#recaptcha-wrapper > div > div {
  margin: auto;
  // margin-top: 22px;
}

.chip-list-scroll {
  .mat-chip-list-wrapper {
    flex-wrap: nowrap;
    overflow: auto;
    overflow-y: hidden;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

.radio-button {
  border: 0.8px solid #fbfbfb;

  .mat-radio-label {
    justify-content: center;

    .mat-radio-outer-circle {
      border-color: get('grey', 200) !important;
    }

    .mat-radio-inner-circle {
      background-color: get('primary', 500) !important;
    }
  }

  &.mat-radio-checked {
    background: get('primary', 10);
    border: 0.8px solid get('primary', 100);

    .mat-radio-outer-circle {
      border-color: get('primary', 500) !important;
    }
  }
}

mat-tab-group {
  .mat-tab-label {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    opacity: 1;
    color: get('grey', 500);

    &.mat-tab-label-active {
      background: get('grey', 200);
      color: get('grey', 800);
    }
  }

  .mat-tab-label-container {
    margin-bottom: 4px;
  }

  mat-ink-bar {
    display: none;
  }
}
