// Define color variables using SCSS maps
$colors: (
  "primary": (
    10:  #F2F6FF,
    50:  #E6EEFF,
    100: #CCDDFF,
    200: #99BBFF,
    300: #6699FF,
    400: #3377FF,
    500: #0055FF,
    600: #0044CC,
    700: #003399,
    800: #002B80,
    900: #002266
  ),
  "grey": (
    10:  #F8F8F9,
    50:  #F1F1F4,
    100: #E3E4E8,
    200: #D5D6DD,
    300: #C7C9D1,
    400: #ABAEBA,
    500: #73788C,
    600: #5C6070,
    700: #454854,
    800: #2E3038,
    900: #000000
  ),
  "error": (
    10:  #FFF3F5,
    50:  #FEE7EC,
    100: #FDCED8,
    200: #FA9EB2,
    300: #F86D8B,
    400: #F63C64,
    500: #F40B3E,
    600: #C30931,
    700: #AA082B,
    800: #920725,
    900: #610519
  ),
  "warning": (
    10:  #FFFCF3,
    50:  #FFF9E6,
    100: #FFF3CC,
    200: #FFE799,
    300: #FFDB66,
    400: #FFCF33,
    500: #FFC400,
    600: #CC9C00,
    700: #B38900,
    800: #997500,
    900: #664E00
  ),
  "info": (
    10:  #F3FBFF,
    50:  #E6F6FF,
    100: #CCEEFF,
    200: #9ADDFE,
    300: #67CCFE,
    400: #34BBFE,
    500: #01AAFE,
    600: #0188CB,
    700: #0177B2,
    800: #016698,
    900: #014465
  ),
  "success": (
    10:  #F4FDFB,
    50:  #E9FCF6,
    100: #D2F9ED,
    200: #A6F2DB,
    300: #79ECC9,
    400: #4DE6B8,
    500: #20DFA6,
    600: #19B385,
    700: #169C74,
    800: #138663,
    900: #0D5942
  )
);

/**
 * Retrieves the color value from the color map.
 *
 * @param {string} $category - The color category (e.g., "primary", "secondary").
 * @param {number} $shade - The shade of the color (default is 100).
 * @returns {color} The color value corresponding to the category and shade.
 */
@function get($category, $shade: 500) {
  $category-colors: map-get($colors, $category);
  @if $category-colors {
    $color-value: map-get($category-colors, $shade);
    @if $color-value {
      @return $color-value;
    } @else {
      @warn "Shade '#{$shade}' not found in '#{$category}' colors.";
      @return null;
    }
  } @else {
    @warn "Color category '#{$category}' not found.";
    @return null;
  }
}