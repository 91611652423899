@import 'theme';

.slick-dots li {
  margin: 0;
}

.slick-dots li button::before {
  color: get('primary', 100);
  opacity: 1;
}


.slick-dots li.slick-active button::before {
  color: get('primary', 400);
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-track{
  display: flex;
  margin-left: unset;
  margin-right: unset;
}


.slick-slide
{
    height: inherit;
}

.row-slide.ssr-fix {
  overflow: auto;
  .slick-slider {
    display: inline-flex;

    product-card {
      width: 127px;
    }
  }
}

.ssr-fix::-webkit-scrollbar {
  width: 0px !important;
}

.ssr-fix::-webkit-scrollbar-thumb {
  background-color: inherit;
}