.banner-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1280px;
}

.card {
  border: 1px solid #CBCBCB;
  box-shadow: 0px 2px 4px #D0D0D0;
  border-radius: 10px;
}

.irs-with-grid .irs-grid,
.irs--round .irs-min,
.irs--round .irs-max,
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  display: none!important;
}

.irs--round .irs-line,
.irs--round .irs-bar {
  top: 20px!important;
}

.irs--round .irs-handle {
  top: 10px!important;
}

.irs--round .irs-bar {
  background-color: $ash-grey!important;
}

.irs--round .irs-line {
  background-color: $platinum!important;
}

.irs--round .irs-handle {
  border: 2px solid $red-orange!important;

  &::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $red-orange;
    top: 6px;
    left: 6px;
  }
}

.full-with-dialog {
  position: relative;
  overflow: auto;
}


@keyframes blink {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes singleblink {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.ng-lazyloading {
  animation-name: blink;
  animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ng-lazyloaded {
  animation-name: singleblink;
  animation-duration: 1s;
  animation-delay: 0s !important;
}

